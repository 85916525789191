import { Box, FormControl, Stack, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';

import { VpFancyButton } from '@vp/common/ui/component/VpFancyButton';
import { VpInput } from '@vp/common/ui/component/VpInput';
import { ProfileHonorNotificationStatus } from '@vp/profile/ui/honor/ProfileHonor';

export interface SubmitRecordFormProps {
  handleFormSubmit: (record: FormData) => Promise<ProfileHonorNotificationStatus>;
  submittingRecord: boolean;
  submitButtonText: string;
}

interface FormData {
  name: string;
  text: string;
}

const TextValidationOptions: RegisterOptions<FormData> = {
  required: true,
  minLength: {
    value: 8,
    message: 'Текст слишком короткий',
  },
  maxLength: {
    value: 1000,
    message: 'Текст слишком длинный',
  },
};

const NameValidationOptions: RegisterOptions<FormData> = {
  required: true,
  minLength: 4,
  maxLength: 50,
};

export const SubmitRecordForm: FC<SubmitRecordFormProps> = ({ handleFormSubmit, submittingRecord, submitButtonText }) => {
  const { register, handleSubmit, formState } = useForm<FormData>({ defaultValues: { name: '', text: '' }, mode: 'onTouched' });
  const { spacing, palette } = useTheme();

  const textInputRef = useRef<HTMLElement>(null!);

  const handleFocus = useCallback(() => {
    if (textInputRef?.current) {
      textInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [textInputRef]);

  return (
    <Stack component="form" width="100%" onSubmit={handleSubmit(handleFormSubmit)}>
      <FormControl disabled={submittingRecord} fullWidth error={!!formState.errors.name}>
        <Typography htmlFor="form-name" component="label" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
          Ваше имя
        </Typography>
        <VpInput {...register('name', NameValidationOptions)} id="form-name" placeholder={'Например, Александр Иванов'} />
      </FormControl>

      <Box height={spacing(3)} />

      <FormControl disabled={submittingRecord} fullWidth error={!!formState.errors.text}>
        <Typography component="label" htmlFor="form-text" variant="formLabelMedium" color="text.primary" mb={spacing(0.75)}>
          {'Запись'}
        </Typography>
        <VpInput
          {...register('text', TextValidationOptions)}
          id="form-text"
          multiline
          minRows={4}
          maxRows={5}
          placeholder={'Например, воспоминания, слова благодарности или просто мысли об ушедшем'}
          inputRef={textInputRef}
          onFocus={handleFocus}
        />
        {formState.errors.text?.message && (
          <Typography variant="body2Regular" color="danger.500" mt={0.75}>
            {formState.errors.text.message}
          </Typography>
        )}
      </FormControl>

      <Box height={spacing(3)} />

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          textAlign: 'center',
          backgroundColor: palette.background.default,
          padding: spacing(2),
          width: 1,
        }}
      >
        <VpFancyButton disabled={!formState.isValid} loading={submittingRecord} type="submit" text={submitButtonText} />
      </Box>
    </Stack>
  );
};
